<template>
  <div>
    <popup v-if="showPopup" :show="showPopup" @change="closePopup" />
    <div class="flex-box" style="width: 100%; justify-content: center">
      <div
        v-if="wData.type == 'long-copy'"
        class="follow-btn flex-box"
        style="cursor: auto; text-align: center"
        :style="wxStyle.wxButton"
        @copy="copyBtn"
      >
        {{ wData.props.wechatNumber || "微信名" }}
      </div>
      <div
        v-else
        class="follow-btn flex-box"
        style="cursor: auto; text-align: center"
        :style="wxStyle.wxButton"
        :data-clipboard-text="wData.props.wechatNumber"
        @click="copyBtn"
      >
        {{ wData.props.buttonContent || "关注" }}
      </div>
    </div>
  </div>
</template>

<script>
import popup from "../popup.vue";
export default {
  components: {
    popup,
  },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          type: "long-copy",
          props: {
            wxButton: {
              bgColor: "",
              color: "",
              borderRadius: 20,
              fontSize: "",
              width: 50,
              height: 25,
            },
          },
        };
      },
    },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    wxStyle() {
      let style = this.wData.props;
      let newStyle = {
        wxButton: {
          "background-color": style.buttonBackgroundColor,
          color: style.buttonFontColor,
          "border-radius": style.buttonFillet + "px",
          fontSize: style.buttonFont,
          width: style.buttonWidth + "%",
          height: style.buttonHeight + "px",
        },
      };
      return newStyle;
    },
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    openPopup() {
      this.showPopup = true;
    },
    copyBtn() {
      this.openPopup();
      this.$emit("copyBtn");
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
}

.follow-btn {
  justify-content: center;
  border-radius: 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
}
</style>
