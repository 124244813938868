<template>
  <div>
    <copyButton
      v-if="wData.type == 'long-copy' || wData.type == 'click-copy'"
      :w-data="wData"
      @copyBtn="copyBtn"
    />
    <wxCopyCard
      v-else-if="wData.type == 'wxCopy'"
      :w-data="wData"
      :opt="opt"
      @copyBtn="copyBtn"
    />
    <wxVCard
      v-else-if="wData.type == 'wx'"
      :w-data="wData"
      :opt="opt"
      @copyBtn="copyBtn"
    />
    <pictureAdd
      v-else-if="wData.type == 'picture-add'"
      :w-data="wData"
      @copyBtn="copyBtn"
    />
    <carouselBox
      v-else-if="wData.type == 'picture-carousel'"
      :w-data="wData"
      @copyBtn="copyBtn"
    />
    <wxGuide
      v-else-if="wData.type == 'guide-wx'"
      :w-data="wData"
      @copyBtn="copyBtn"
    />
    <officalGuide
      v-else-if="wData.type == 'guide-official'"
      :w-data="wData"
      @copyBtn="copyBtn"
    />

    <wxText
      v-else-if="wData.type == 'text' || wData.type == 'text-wx'"
      :w-data="wData"
      @copyBtn="copyBtn"
    />
    <interaction
      v-else-if="
        wData.type == 'issue' ||
        wData.type == 'action-issue' ||
        wData.type == 'flicker-issue'
      "
      :w-data="wData"
      :opt="opt"
      @copyBtn="copyBtn"
    />
    <div
      v-else-if="wData.type == 'bottom-wx' || wData.type == 'bottom-long-wx'"
      class="bottom-fixd"
    ></div>
  </div>
</template>

<script>
import copyButton from "./template/copyButton.vue";
import wxCopyCard from "./template/wxCopyCard.vue";
import wxVCard from "./template/wxVCard.vue";
import pictureAdd from "./template/picture.vue";
import carouselBox from "./template/carousel.vue";
import officalGuide from "./template/officalGuide.vue";
import wxGuide from "./template/wxGuide.vue";
import wxText from "./template/wxText.vue";
import interaction from "./template/interaction.vue";
export default {
  components: {
    copyButton,
    wxCopyCard,
    wxVCard,
    pictureAdd,
    carouselBox,
    wxGuide,
    officalGuide,
    wxText,
    interaction,
  },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          type: "",
          prop: {},
          style: {},
          script: {},
        };
      },
    },
    opt: {
      type: Object,
      default() {
        return {
          isEditPreview: false,
          currentPage: 0,
          currentWidget: 0,
        };
      },
    },
  },
  data() {
    return {};
  },
  
  methods: {
    copyBtn() {
      this.$emit("copyBtn");
    },
  },
};
</script>

<style></style>
