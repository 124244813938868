<template>
  <div style="display: flex; justify-content: center">
    <p :style="textStyle">
      {{ wData.props.textPre || "默认文本文字" }}
      <span v-if="wData.type == 'text-wx'" style="color: red">{{
        wData.props.wechatNumber
      }}</span>
      <span v-if="wData.type == 'text-wx'">{{ wData.props.textSuf }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          type: "text",
          props: {
            textPre: "默认文本文字",
            wechatNumber: "",
            textSuf: "",
            font: "16px",
            color: "",
            horizontalAxis: "",
            longitudinalAxis: "",
            width: 320,
          },
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    textStyle: function () {
      let style = this.$props.wData.props;
      let newStyle = {
        fontSize: style.font,
        marginLeft: style.horizontalAxis + "px",
        marginTop: style.longitudinalAxis + "px",
        width: style.width + "px",
        color: style.color,
      };
      return newStyle;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
p {
  display: block;
  width: 400px;
  min-height: 20px;
  padding: 0;
  margin: 0;
  text-align: center;
}
</style>
