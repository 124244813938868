<template>
  <div class="carouselBox">
    <el-carousel
      :arrow="arrow"
      :style="imgHeight"
      :type="typeCard"
      :indicator-position="indicatorPosition"
      :autoplay="autoplay"
      :loop="loop"
      :interval='interval'
    >
      <el-carousel-item
        v-for="(item, index) in wData.props.photoList"
        :key="index"
      >
       <div :style="imgHeight">
          <img :src="item.url" alt="" class='setImg' />
       </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
// import popup from "../popup.vue";
//import img from '../../assets/v-img.webp'
export default {
  components: {
    // popup,
  },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "picture-carousel",
          props: {
            rotationType: 1, //图片轮播类型（1.轮播图 2.相册）
            height: 100, //组件高度
            indicationPoint: 0, // 是否展示指示点 0.展示 1.不展示
            loops: 0, //是否环路 0.环路 1.不环路
            automaticRotation: 100, //是否自动轮播 0.自动轮播 1.不自动轮播
            rotationTime: 6000, //轮播时间（ms）默认6000ms
            photoList: [
              {
                rotationId: 1,
                url: require("@/assets/url1.webp"),
                label: "",
                link: "",
                sort: "",
              },
            ],
          },
        };
      },
    },
  },
 
  computed: {
    imgHeight() {
      return {
        width: "100%",
        height: this.wData.props && this.wData.props.height + "px",
        overflow: "hidden",
      };
    },
    typeCard() {
      return this.wData.props.rotationType == 2 ? "card" : "";
    },
    indicatorPosition() {
      return this.wData.props.indicationPoint === 1 ? "" : "none";
    },
    autoplay() {
      return this.wData.props.automaticRotation === 0 ? false : true;
    },
    loop() {
      return this.wData.props.loops === 0 ? false : true;
    },
    arrow(){
      return this.wData.props.automaticRotation === 0 ? 'always' : 'never';
    },
     interval(){
      return this.wData.props.rotationTime
    }
  },
  data() {
    return {};
  },
};
</script>
<style>
.carouselBox {
  z-index: 0;
}
.el-carousel__button {
  background-color: #eee;
}
.setImg{
  width:100%;
  height:100%;
}
</style>
