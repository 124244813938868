<template>
  <div>
    <popup
      v-if="showPopup"
      :show="showPopup"
      :wx-number="wData.props.wechatNumber"
      :box-type="wData.props.popupType"
      :head-src="wData.props.src"
      @change="closePopup"
    ></popup>
    <div :id="wData.id" class="weixin-box" :style="wxStyle.baseStyle">
      <div class="big" style="display: flex; justify-content: center">
        关注微信号
        <div class="weixin" :style="wxStyle.weixNumStyle" @copy="copyBtn">
          {{ wData.props.wechatNumber || "微信号" }}
        </div>
      </div>
      <p class="small" :style="wxStyle.smallStyle">
        {{ wData.props.content }}
      </p>
    </div>
  </div>
</template>

<script>
import popup from "../popup.vue";

export default {
  components: {
    popup,
  },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "wxCopy",
          props: {
            wechatNumber: "weixin帐号",
            content: "长按复制微信号，去微信搜索添加",
            basicFont: "",
            basicFontColor: "",
            basicFontBackgroundColor: "",
            wechatNumberFont: "",
            wechatNumberColor: "",
            copywritingFont: "",
            horizontalAxis: "",
            longitudinalAxis: "",
            height: "",
          },
        };
      },
    },
  },
  data() {
    return {
      showPopup: false,
      number: 1,
    };
  },
  computed: {
    wxStyle: function () {
      let newStyle = {
        baseStyle: {
          fontSize: this.$props.wData.props.basicFont,
          marginLeft: this.$props.wData.props.horizontalAxis + "px",
          marginTop: this.$props.wData.props.longitudinalAxis + "px",
          height: this.$props.wData.props.height + "px",
          color: this.$props.wData.props.basicFontColor,
          backgroundColor: this.$props.wData.props.basicFontBackgroundColor,
        },
        weixNumStyle: {
          color: this.$props.wData.props.wechatNumberColor,
          fontSize: this.$props.wData.props.wechatNumberFont,
        },
        smallStyle: {
          fontSize: this.$props.wData.props.copywritingFont,
        },
      };
      return newStyle;
    },
  },
  mounted() {},
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    openPopup() {
      this.showPopup = true;
    },
    copyBtn() {
      console.error("sdfsdfsdfsdf");
      this.openPopup();
      this.$emit("copyBtn");
    },
  },
};
</script>

<style scoped>
.weixin-box {
  width: 100%;
  min-height: 80px;
  background-color: rgb(36, 121, 233);
  color: #fff;
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box;
  letter-spacing: 1px;
  overflow: hidden;
}

.weixin {
  color: chocolate;
  font-weight: bold;
  /*cursor: pointer;*/
}

/* .big {
  font-size: 1rem;
} */

.small {
  font-size: 0.8rem;
  margin-top: 0.3rem;
}
</style>
