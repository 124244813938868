<template>
  <div>
    <popup
      v-if="showPopup"
      :show="showPopup"
      @change="closePopup"
      :wx-number="wData.props.wechatNumber"
      :box-type="wData.props.popupType"
      
    />
    <div class="flex-box" style="width: 100%; justify-content: center">
      <div class="imgbox">
        <div class="imgbox_item">
          <img
            :src="require('../../assets/wxGuide1.jpg')"
            alt=""
            :style="picsty"
          />
        </div>
        <div class="imgbox_item">
          <div>
            2.粘贴复制好的微信号或者输入"{{ wData.props.wechatNumber }}"搜索
          </div>
          <img
            :src="require('../../assets/wxGuide2.jpg')"
            alt=""
            :style="picsty"
          />
          <span class="imgbox_item_wechatNumber1">{{
            wData.props.wechatNumber
          }}</span>
        </div>
        <div class="imgbox_item">
          <img
            :src="require('../../assets/wxGuide3.jpg')"
            alt=""
            :style="picsty"
            class="imgbox_item_bck"
          />
          <img
            :src="wData.props.photoUrl"
            alt=""
            class="imgbox_item_photoUrl"
          />
          <span class="imgbox_item_wechatNumber2">{{
            wData.props.wechatNumber
          }}</span>
        </div>
      </div>
    </div>
    <div class="officialBtn">
      <button @click="openPopup">微信号已复制，打开微信</button>
    </div>
  </div>
</template>

<script>
import popup from "../popup.vue";

export default {
  components: {
    popup,
  },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          type: "guide-wx",
          props: {
            wechatNumber: "s", //微信号
            popupType: 0, //  弹窗类型（0.点击复制 1.长按复制）
            photoUrl: "", //图片
          },
        };
      },
    },
  },
 
  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    picsty() {
      let style = this.wData.props;
      let picsty = {
        width: style.width + "%",

        height: style.height + "%",
      };
      return picsty;
    },
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    openPopup() {
      this.showPopup = true;
    },
    copyBtn() {
      this.openPopup();
      this.$emit("copyBtn");
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
}

.follow-btn {
  justify-content: center;
  border-radius: 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
}
.imgbox {
  /*  font-size: 0; */
  padding: 0 10px;
  box-sizing: border-box;
}
.imgbox_item {
  border: 1px solid #333;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  position: relative;
}

.imgbox_item_wechatNumber1 {
  position: absolute;
  left: 20px;
  top: 60px;
  font-size: 12px;
}
.imgbox_item_wechatNumber2 {
  position: absolute;
  left: 100px;
  top: 70px;
  font-size: 12px;
}
.imgbox_item > img {
  width: 100%;
  /*  height: 100%; */
}
.imgbox_item_photoUrl {
  position: absolute;
  left: 30px;
  top: 50px;
  font-size: 12px;
  width: 60px !important;
  height: 60px;
}
.officialBtn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.officialBtn > button {
  display: block;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  background-color: #ff2a2a;
  color: #fff;

  text-align: center;
  cursor: pointer;
}
</style>
