<template>
  <div :style="[wxStyle.bodyStyle, wxStyle.boxStyle]">
    <div :style="wData.props.finger ? 'padding-bottom:0.7rem;' : ''">
      <popup
        v-if="showPopup"
        :show="showPopup"
        :wx-number="wData.props.wechatNumber"
        :box-type="wData.props.popupType"
        :head-src="wData.props.src"
        :qrcode="wData.props.qrcode"
        @change="closePopup"
      ></popup>
      <div
        :id="wData.id"
        class="user-box flex-box"
        :style="wData.props.finger ? 'padding-bottom:0;' : ''"
      >
        <div class="user-image-name flex-box">
          <div class="flex-box">
            <img
              :src="wData.props.wechatPictureUrl"
              class="user-head"
              :style="wxStyle.imgStyle"
            />
            <span
              v-if="wData.type != 'bottom-long-wx'"
              class="user-name"
              :style="wxStyle.weixinNameStyle"
              >{{ wData.props.wechatNumber || "微信号" }}</span
            >
          </div>
          <div
            v-if="wData.type == 'bottom-long-wx'"
            :style="[
              { fontSize: wxStyle.bodyStyle.fontSize },
              { color: wxStyle.bodyStyle.color },
            ]"
          >
            {{ wData.props.assemblyContent }}
          </div>
          <div v-if="wData.type == 'bottom-long-wx'" style="text-align: center">
            <div
              class="follow-btn flex-box"
              :style="wxStyle.weixinButton"
              @copy="copyBtn"
            >
              {{ wData.props.wechatNumber || "微信名" }}
            </div>
            <div style="font-size: 0.6rem">(长按复制)</div>
          </div>
          <div
            v-else
            :data-clipboard-text="wData.props.wechatNumber"
            :style="wxStyle.weixinButton"
            class="flex-box follow-btn"
            @click="copyBtn"
          >
            {{ wData.props.followButtonContent || "关注" }}
          </div>
        </div>
      </div>
      <div v-if="wData.props.finger" class="bottom-finger flex-box">
        <img :src="require('../../assets/finger.gif')" alt="finger.image" />
      </div>
    </div>
  </div>
</template>

<script>
import popup from "../popup.vue";
export default {
  components: {
    popup,
  },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "未命名",
          type: "wx",
          props: {
            assemblyBackgroundColor: "#ffffff",
            assemblyFontColor: "",
            assemblyHeight: "",
            assemblyFont: "14px",
            followButtonBackgroundColor: "#13ce66",
            followButtonFontColor: "#ffffff",
            followButtonFont: "14px",
            followButtonContent: "关注",
            followButtonFillet: 20,
            followButtonHeight: "25",
            followButtonWidth: "50",
            wechatNumber: "weixin帐号",
            wechatNumberFont: "",
            wechatNumberColor: "",
            wechatPictureUrl:
              "http://240ps.com/jc/Dfile/20160907/j1609072_2.jpg",
            wechatPictureHeight: 20,
            wechatPictureWidth: 20,
            popupType: "",
            finger: "",
          },
        };
      },
    },
    opt: {
      type: Object,
      default() {
        return {
          isEditPreview: false,
          currentPage: 0,
          currentWidget: 0,
        };
      },
    },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    wxStyle: function () {
      let style = this.$props.wData.props;
      let newStyle = {
        imgStyle: {
          width: style ? style.wechatPictureWidth / 10 + "rem" : "2rem",
          height: style ? style.wechatPictureHeight / 10 + "rem" : "2rem",
        },
        weixinNameStyle: {
          fontSize: style.wechatNumberFont,
          color: style.wechatNumberColor,
        },
        weixinButton: {
          "background-color": style.followButtonBackgroundColor,
          color: style.followButtonFontColor,
          "border-radius": style
            ? style.followButtonFillet / 10 + "rem"
            : "2rem",
          fontSize: style.followButtonFont,
          width: style ? style.followButtonWidth / 10 + "rem" : "2rem",
          height: style.followButtonHeight
            ? style.followButtonHeight / 10 + "rem"
            : "2rem",
        },
        boxStyle: {
          height: style.assemblyHeight
            ? style.assemblyHeight / 10 + "rem"
            : "unset",
        },
        bodyStyle: {
          "background-color": style.assemblyBackgroundColor
            ? style.assemblyBackgroundColor
            : "#fff",
          fontSize: style.assemblyFont ? style.assemblyFont : "unset",
          color: style.assemblyFontColor ? style.assemblyFontColor : "unset",
        },
      };
      return newStyle;
    },
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    openPopup() {
      this.showPopup = true;
    },
    copyBtn() {
      if (this.wData.props.popupType == 4) {
        console.log(this.wData.props.popupType, "this.wData.props.popupType");
        this.opt.currentPage++;
        this.opt.currentWidget = 0;
      } else {
        this.openPopup();
        this.$emit("copyBtn");
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
}

.user-box {
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
  padding: 0.7rem 0.5rem;
}
.user-image-name {
  width: 100%;
  justify-content: space-between;
}
.user-head {
  width: 2rem;
  min-height: 20px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.follow-btn {
  justify-content: center;
  border-radius: 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
}
.bottom-finger {
  width: 100%;
  flex-direction: row-reverse;
}
.bottom-finger img {
  width: 0.9rem;
  height: 1.2rem;
  margin-right: 2.5rem;
}
</style>
