<template>
  <div>
    <popup v-if="showPopup" :show="showPopup" @change="closePopup" :wx-number="wData.props.wechatNumber"
      :box-type="wData.props.popupType"/>
    <div class="flex-box" style="width: 100%; justify-content: center">
      <div class="imgbox">
        <div class="imgbox_item" :style="wxStyle">
          <div :style="fontStyle">1.点击搜索</div>
          <img :src="wData.props.photoUrl" alt="" :style="picsty" />
        </div>
        <div class="imgbox_item" :style="wxStyle">
          <div :style="fontStyle">2.粘贴[{{ wData.props.wechatNumber }}]然后点击</div>
          <img :src="wData.props.photoUrl2" alt="" :style="picsty" />
        </div>
        <div class="imgbox_item" :style="wxStyle">
          <div :style="fontStyle">3.点击关注公众号</div>
          <img :src="wData.props.photoUrl3" alt="" :style="picsty" />
        </div>
      </div>
     
    </div>
     <div class="officialBtn">
        <button @click="openPopup">微信号已复制，打开微信</button>
      </div>
  </div>
</template>

<script>
import popup from "../popup.vue";
export default {
  components: {
    popup,
  },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          type: "guide-official",
          props: {
            guideType: 1, // 引导类别(0.微信引导 1.公众号引导)
            wechatNumber: "s", //微信号
            popupType: 0, //  弹窗类型（0.点击复制 1.长按复制）
            photoUrl: "", //图片
            photoUrl2: "", // 图片2
            photoUrl3: "", //图片3
            backgroundColor: "#eee", //背景颜色
            font: 24, // 字体大小
            fontColor: "#eeeeee", // 字体颜色
          },
        };
      },
    },
  },
 
  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    picsty() {
      let style = this.wData.props;
      let picsty = {
        width: style.width + "%",

        height: style.height + "%",
      };
      return picsty;
    },
    wxStyle: function () {
      let newStyle = {
      backgroundColor: this.$props.wData.props.backgroundColor,
      };
      return newStyle;
    },
     fontStyle: function () {
      let fontStyle = {
      color: this.$props.wData.props.fontColor,
      fontSize:this.$props.wData.props.font

      };
      return fontStyle;
    },
  },
  
 
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    openPopup() {
      this.showPopup = true;
    },
    copyBtn() {
      this.openPopup();
      this.$emit("copyBtn");
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
}

.follow-btn {
  justify-content: center;
  border-radius: 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
}
.imgbox {
 /*  font-size: 0; */
  padding: 0 10px;
  box-sizing: border-box;
}
.imgbox_item {
  border: 1px solid #333;
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.imgbox_item > img {
  width: 100%;
 /*  height: 100%; */
}
.officialBtn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.officialBtn > button {
  display: block;
 width:200px;
 height:40px;
 line-height:40px;
 text-align: center;
 border-radius: 10px;
  background-color: #ff2a2a;
  color: #fff;
 
  text-align: center;
  cursor: pointer;
}
</style>
