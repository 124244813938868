<template>
  <div :style="newStyle.bodyStyle" style="padding-bottom: 0.5rem">
    <div v-if="wData.type == 'flicker-issue'" class="transtion-cotent">
      <div v-for="(item, index) in wData.props.questions" :key="index">
        <div class="auto-title">
          <div class="question-box" :style="newStyle.questionStyle">
            {{ item.question }}
          </div>
        </div>
        <div class="flex-box options-box">
          <div
            v-for="(value, key) in item.options"
            :key="key"
            class="options-item"
            :style="newStyle.optionStyle"
            :class="[actionObj[index] == key ? 'options-action-item' : '']"
            @click="handleClickAction(index, key)"
          >
            {{ value }}
          </div>
        </div>
      </div>
      <div v-if="showBtn" class="action-btn" @click="handleChangePage">
        {{ wData.props.buttonContent }}
      </div>
    </div>
    <div v-else>
      <div v-for="(item, index) in wData.props.questions" :key="index">
        <div class="auto-title">
          <div class="title-box" :style="newStyle.questionStyle">
            {{ item.question }}
          </div>
        </div>
        <div class="flex-box options-box">
          <div
            v-for="(value, key) in item.options"
            :key="key"
            class="options-item"
            :style="newStyle.optionStyle"
            :class="[actionObj[index] == key ? 'options-action-item' : '']"
            @click="handleClickAction(index, key)"
          >
            {{ value }}
          </div>
        </div>
      </div>
      <div v-if="showBtn" class="action-btn" @click="handleChangePage">
        {{ wData.props.buttonContent || "默认按钮" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          type: "issue",
          props: {
            backgroundColor: "",
            questionFont: "",
            questionFontColor: "",
            questionFontFillColor: "",
            questionFillet: "",
            optionFont: "",
            optionFontColor: "",
            optionFontFillColor: "",
            optionFillet: "",
            buttonContent: "",
            questions: [
              {
                question: "标题",
                options: ["选项1", "选项2"],
                sort: 1,
              },
            ],
          },
        };
      },
    },
    opt: {
      type: Object,
      default() {
        return {
          isEditPreview: false,
          currentPage: 0,
          currentWidget: 0,
        };
      },
    },
  },
  data() {
    return {
      actionObj: {},
      showBtn: false,
    };
  },
  computed: {
    newStyle: function () {
      let data = this.$props.wData.props;
      let newStyle = {
        questionStyle: {
          fontSize: data.questionFont,
          color: data.questionFontColor,
          "background-color": data.questionFontFillColor,
          "border-radius": data.questionFillet + "px",
        },
        optionStyle: {
          fontSize: data.optionFont,
          color: data.optionFontColor,
          "background-color": data.optionFontFillColor,
          "border-radius": data.optionFillet + "px",
        },
        bodyStyle: {
          "background-color": data.backgroundColor,
        },
      };
      return newStyle;
    },
  },
  mounted() {
    if (this.wData.type !== "issue") {
      this.showBtn = true;
    }
  },
  methods: {
    handleClickAction(i, k) {
      this.actionObj[i] = k;
      if (
        this.wData.type == "issue" &&
        this.wData.props.questions.length == Object.keys(this.actionObj).length
      ) {
        this.showBtn = true;
      }
      this.$forceUpdate();
    },
    handleChangePage() {
      this.opt.currentPage++;
      this.opt.currentWidget = 0;
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
}
.auto-title {
  padding: 1.5rem 0.8rem 0 0.8rem;
}
.title-box {
  display: inline-block;
  font-size: 0.7rem;
  line-height: 1.9rem;
  background-color: #ffe4a1;
  border-radius: 1.25rem;
  color: #000000;
  padding: 0 0.7rem;
  border: 1px solid #712624;
}
.options-box {
  position: relative;
  margin: 0.5rem 0 0;
  flex-wrap: wrap;
}
.question-box {
  text-align: center;
  color: #000000;
  line-height: 1.9rem;
  padding: 0 0.7rem;
}
.options-item {
  position: relative;
  min-height: 1.9rem;
  width: 27%;
  border-radius: 6px;
  background: linear-gradient(to right, #f3d8ae, #efc78a);
  line-height: 1.9rem;
  text-align: center;
  color: #000000;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  margin: 1rem 2.375% 0;
  box-sizing: border-box;
}
.options-action-item::after {
  content: "";
  background: url(http://www.mic-dt-vip.com/upload/mk/ad/image/997364449b284d5e88f8cf029620e8b9.png)
    no-repeat center center;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
}
.action-btn {
  width: 10rem;
  margin: 2rem auto 0;
  background-color: #ff2a2a;
  color: #fff;
  font-size: 1.2rem;
  line-height: 2rem;
  -webkit-box-shadow: 0 0.3rem 0 #e80204;
  box-shadow: 0 0.3rem 0 #e80204;
  border-radius: 1.5rem;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.transtion-cotent {
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
}
.transtion-cotent .action-btn {
  animation: bgKeyframes 1s linear infinite;
}
@keyframes bgKeyframes {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
</style>
