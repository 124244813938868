<template>
  <div>
    <div v-if="show" class="popup-wrap" @click="closePopup">
      <!-- 长按复制 -->
      <div v-if="type == 1" class="go-weixin animation-box" @click.stop>
        <p class="page-close">
          <img
            id="stepId"
            src="http://datanc.oss-cn-hangzhou.aliyuncs.com/buildHtml/colse.png"
            @click="closePopup()"
          />
        </p>
        <p class="wxh">
          长按复制微信号
          <span ref="copyBox" class="wex-num" @copy="myCopy()">{{
            wxNumber
          }}</span>
        </p>
        <p class="step-img">
          <img
            src="http://datanc.oss-cn-hangzhou.aliyuncs.com/buildHtml/step.jpg"
          />
        </p>
      </div>
      <!-- 点击复制 -->
      <div
        v-else-if="type == 2 || type === 0"
        class="popup-box animation-box"
        @click.stop
      >
        <div class="title">
          <div class="success-icon"></div>
          <div class="notice-title">复制成功</div>
        </div>
        <div class="content">
          您已成功复制微信号，是否立即跳转到微信搜索该微信号？
        </div>
        <div class="btn-box flex-box">
          <div class="btn popup-cancle" @click="closePopup">取消</div>
          <div class="btn popup-close" @click="toWx">确定</div>
        </div>
      </div>
      <!-- 二维码 -->
      <div
        v-else-if="type == 3"
        class="qrcode-box flex-box animation-box"
        @click.stop
      >
        <img
          src="data:image/svg+xml,%3Csvg viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill-opacity='.01' fill='%23FFF' d='M0 0h50v50H0z'/%3E%3Cpath d='M25 2.083a22.847 22.847 0 0116.205 6.712A22.847 22.847 0 0147.917 25a22.847 22.847 0 01-6.712 16.205A22.847 22.847 0 0125 47.917a22.847 22.847 0 01-16.205-6.712A22.847 22.847 0 012.083 25 22.847 22.847 0 018.795 8.795 22.847 22.847 0 0125 2.083zm8.944 15.194L22.917 28.304l-4.777-4.777a2.083 2.083 0 00-2.946 2.946l6.25 6.25a2.083 2.083 0 002.946 0l12.5-12.5a2.083 2.083 0 10-2.946-2.946z' fill='%2366C23A'/%3E%3C/g%3E%3C/svg%3E"
          alt="复制成功"
        />
        <p>微信号已复制</p>
        <div class="qrcode-wx flex-box">
          <p>{{ wxNumber }}</p>
          <img :src="qrcode" alt="二维码" />
        </div>
        <div class="qrcode-tip flex-box">
          <p>保存二维码到手机相册!</p>
          <p>如遇添加频繁请使用二维码添加！</p>
        </div>
        <div class="qrcode-btn flex-box">
          <button @click="closePopup">取消</button>
          <button @click="toWx">去微信添加</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    wxNumber: {
      type: String,
      default: "微信号",
    },
    boxType: {
      type: Number,
      default: 0,
    },
    headSrc: {
      type: String,
      default: "https://ldy.liuyanniangzhi.com/ta42/kftx.png",
    },
    qrcode: {
      type: String,
      default:
        "https://wxqrcode.ljwit.com/wx_qrcode/1744/5b4b3692c4ca0ad42192f910c64dc2f8.jpg",
    },
  },
  data() {
    return {
      closeStatus: false,
      type: 0,
    };
  },
  computed: {
    getBoxType() {
      this.setValue();
      return this.boxType;
    },
  },
  created() {
    this.type = this.boxType;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.type == 1) {
        this.$refs.copyBox.addEventListener("copy", this.myCopy);
      }
    });
  },
  methods: {
    setValue() {
      this.type = this.boxType;
    },
    closePopup() {
      this.closeStatus = true;
      setTimeout(() => {
        this.$emit("change");
        this.closeStatus = false;
      }, 300);
    },
    toWx() {
      window.parent.postMessage(
        {
          msg: "click",
        },
        "*"
      );
      window.open("weixin://");
    },
    myCopy() {
      this.$nextTick(() => {
        this.type = 2;
      });
    },
  },
};
</script>

<style scoped>
img {
  display: block;
  width: 100%;
}
.page-close {
  width: 1.5rem;
  height: 3rem;
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  text-align: center;
  cursor: pointer;
}
.go-weixin {
  width: 18rem;
  height: 20rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 1.5rem 1rem 1rem;
  box-sizing: border-box;
  text-align: center;
  transition: 0.3s;
}
.wxh {
  width: 100%;
  height: 6rem;
  color: rgb(255, 100, 0);
  font-size: 1.5rem;
}

.wex-num {
  background-color: rgb(255, 100, 0);
  color: #fff;
  padding: 0.7rem 0;
  font-size: 1rem;
  display: block;
  margin: 1rem 0;
}

.step-img {
  margin-top: 0.1rem;
}
.qrcode-box {
  position: absolute;
  padding: 1rem 1rem;
  border-radius: 1rem;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  justify-content: center;
  font-size: 0.9rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333;
  transition: all 0.3s;
}
.qrcode-box img {
  width: 3rem;
  height: auto;
}
.qrcode-box p {
  padding-bottom: 0.7rem;
}
.qrcode-wx {
  width: 15rem;
  background-color: rgba(216, 216, 216, 0.3);
  padding: 1rem 0 1rem;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
}
.qrcode-wx img {
  width: 75%;
  height: auto;
}
.qrcode-btn {
  width: 100%;
  padding: 1rem 0 0;
  justify-content: space-between;
  /* padding: 0 2.6rem; */
  padding-bottom: 0.8rem;
}
.qrcode-btn button {
  border: none;
  background-color: #66c23a;
  border-radius: 0.5rem;
  color: #fff;
  width: 7em;
  line-height: 2.5em;
}
.qrcode-tip {
  color: #999;
  flex-direction: column;
  margin-top: 1rem;
}
.popup-wrap {
  position: fixed;
  width: 100%;
  max-width: 500px;
  height: 100%;
  top: 0;
  z-index: 99;
  background-color: rgba(51, 51, 51, 0.5);
  transition: background-color 0.3s;
  animation: opacity 0.3s 1;
}
.popup-box {
  width: 15rem;
  background: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 0.9rem;
  text-align: center;
  border-radius: 0.10666667rem;
  transition: 0.3s;
}
@keyframes opacity {
  0% {
    background-color: rgba(51, 51, 51, 0);
  }

  100% {
    background-color: rgba(51, 51, 51, 0.5);
  }
}

.animation-box {
  animation: show 0.3s 1;
}

@keyframes show {
  0% {
    transform: translate(-50%, 100vh);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}
.title {
  margin-top: 1rem;
  font-size: 2rem;
}
.success-icon {
  background: url(http://datanc.oss-cn-hangzhou.aliyuncs.com/buildHtml/icon.png)
    no-repeat 50%;
  background-size: contain;
  width: 4rem;
  height: 4rem;
  display: block;
  margin: 0 auto 0.5rem;
  vertical-align: middle;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.btn-box {
  justify-content: space-between;
  padding: 0 2.6rem;
  padding-bottom: 1.1rem;
}
.flex-box {
  display: flex;
  align-items: center;
}
.btn {
  line-height: 2.85333333rem;
  border-radius: 4.66666667rem;
  font-size: 1rem;
  padding: 0 1rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.popup-cancle {
  border: 1px solid #999;
  color: #999;
}
.popup-close {
  border: 1px solid #00d48c;
  color: #00d48c;
}
.content {
  padding: 1rem;
}
/* // 防止不生效，放在最底下 */
.hidden-box {
  transform: translate(-50%, 100vh);
}
</style>
