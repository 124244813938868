<template>
  <div>
    <popup v-if="showPopup" :show="showPopup" @change="closePopup" />
    <div
      class="flex-box"
      style="width: 100%; height: 100%; justify-content: center"
    >
      <div class="imgbox" :style="picsty">
        <img :src="wData.props.photoUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import popup from "../popup.vue";
export default {
  components: {
    popup,
  },
  props: {
    wData: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          type: "picture-add",
          props: {
            photoUrl: "",
            horizontalAxis: "",
            longitudinalAxis: "",
            height: 0,
            width: 100,
          },
        };
      },
    },
  },

  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    picsty() {
      // let style = this.wData.props;
      let picsty = {
        width: this.wData.props && this.wData.props.width + "%",
        height: this.wData.props && this.wData.props.height + "px",
      };
      return picsty;
    },
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    openPopup() {
      this.showPopup = true;
    },
    copyBtn() {
      this.openPopup();
      this.$emit("copyBtn");
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  align-items: center;
}

.follow-btn {
  justify-content: center;
  border-radius: 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
}
.imgbox {
  font-size: 0;
}
.imgbox > img {
  width: 100%;
  height: 100%;
}
</style>
